import Header1 from '../components/header1/header1';
import './contactus.css';
import section1 from './section1.jpg';
import contactImg from './contactCardImage.jpg';
import Footer from '../components/footer/footer';
import map from './map.jpg';
import useForm from './useForm';
import validate from './validate';

export default function ContactUs(){
    const {handleChange, values,handleSubmit, errors } = useForm(validate);
    return<>
        <Header1 />
        {/* ----------------------------------------------Section 1----------------------------------------------------- */}
        <div className='container-fluid' id="csection1">
            
            <img src={section1} alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-4 " id="csec1" >
                    <h1>Contact Us</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-4 col-lg-1 " id="chomeTocovid">
                <h6>Home {'>'} <span>Contact Us</span></h6>
            </div>
            </div>
        </div>
        {/* ----------------------------------------------Contact Card----------------------------------------------- */}
        <div className='container-fluid'>
            <div className='row justify-content-center'>
                <div className='col-6 col-lg-9'>
                    <div className="card mb-3" id="contactCard">
                        <div className="row ">
                            <div className="col-lg-7">
                                <form className="row justify-content-center g-4" onSubmit={handleSubmit} id="form4contact">
                                    <div className="col-lg-5">
                                    <input  type="text" 
                                name="username" 
                                className="form-control" 
                                value={values.username}
                                onChange={handleChange} 
                                id="ps1"
                                placeholder="Name" />
                        {errors.username && <p id="cnameErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.username}</p>}
                        
                                    </div>
                                    <div className="col-lg-5">
                                    <input  type="text" 
                                name="email"
                                className="form-control" 
                                value={values.email}
                                onChange={handleChange} 
                                id="ps1"
                                placeholder="Email" />
                    {errors.email && <p id="cemailErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.email}</p>} 

                                    </div>
                                    <div className="col-lg-10">
                                    <input  type="text"
                                name="subject" 
                                className="form-control"
                                value={values.subject}
                                onChange={handleChange} 
                                id="ps1"
                                placeholder="Subject" />
                    {errors.subject && <p id="csubjectErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.subject}</p>} 

                                    </div>
                                    <div className="col-lg-10">
                                    <textarea className="form-control" 
                          rows="3"
                          name="comments" 
                          placeholder='Comments'
                          id="ps1"
                          value={values.comments}
                          onChange={handleChange} 
                ></textarea>
                                    </div>
                                    <div className="col-12 col-lg-10 ">
                                    <button type="submit" id="rfpButton" className="btn btn-primary">SUBMIT RFP</button>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="col-lg-5" id='contactText'>
                                {/* <img src={contactImg} className="img-fluid rounded-start" alt="..." /> */}
                                <p id="cc5">Valis has locations in San Francisco, CA,  Berkeley CA, and New York, NY.</p>
                                <p id="cc6"> 2111 San Pablo Ave
                                <br />PO Box 2054
                                <br />Berkeley California, 94702</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* -------------------------------------------------Map--------------------------------------------- */}
        <img src={map} class="img-fluid" id="map" alt="Map"></img>
    <div className='shieldFooter'>
    <Footer />
    </div>
    </>
}