import axios from 'axios';
import {useState, useEffect} from 'react';
import Swal from 'sweetalert2';



const useForm = (validate) => {

    // const [username, setUsername] = useState('');
    // const [email, setEmail] = useState('');
    // const [subject, setsubject] = useState('');
    // const [comments, setComments] = useState('');

    const [values, setValues] = useState({
        username:'',
        email:'',
        subject: '',
        comments: ''
    });
    
    // const [success, setSuccess]= useState(false);
    const [errors, setErrors]= useState({});

    const handleChange = e => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

   
    const [posts, setPosts] = useState([]);
    const [errMsg, setErrMsg] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        // let name = e.target.username.value;
        // var email = e.target.email.value;
        // let subject = e.target.subject.value;
        // let comments = e.target.comments.value;
            setErrors(validate(values));
            if(!values.username.trim()){
                errors.username = "Please fill in your name"
                return
            }
        
            else if(values.username.length<4){
                errors.username="Name must not be less than 4 characters"
                return
            }
        
            else if(!values.subject.trim()){
                errors.subject = "Please fill in subject"
                return
            }
        
            else if(!values.email){
                errors.email = "Please enter your email"
                return
            }
            else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)){
                
               
                errors.email = "Email is invalid";
                return
                
            }
           
            else if(values.comments.length<1){
                
                // console.log("Name: "+ name + "\n" +"Email: " + email + "\n" + "Subject: " + subject);
                console.log(values);
                axios.post('https://622f22b73ff58f023c1717f3.mockapi.io/fakeData', values)
                    .then(response =>{
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                       
                    })
                
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your inquiry is received...',
                        showConfirmButton: false,
                        timer: 1700,
                        width: '520px',
                        background: '#1B98F5',
                        heightAuto: false,
                        color: '#ffffff',
                        customClass: 'forSwal'
                      })
                      values.username='';
                      values.email='';
                      values.subject= '';
            }

            else{

                // setTimeout(()=>{
                //     setSuccess(true)
                // },2000)
                   
                console.log(values);
                axios.post('https://622f22b73ff58f023c1717f3.mockapi.io/fakeData', values)
                    .then(response =>{
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                       
                    })

                axios.get('https://622f22b73ff58f023c1717f3.mockapi.io/fakeData')
                    .then(response => {
                        console.log(response)
                        setPosts(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                        setErrMsg('Error retrieving data');
                    })
                
               
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Your inquiry is received...',
                        showConfirmButton: false,
                        timer: 1700,
                        width: '520px',
                        background: '#1B98F5',
                        heightAuto: false,
                        color: '#038AF6',
                        customClass: 'forSwal'
                      })
                    
                      values.username='';
                      values.comments='';
                      values.email= '';
                      values.subject= '';

            }     
        
    };


    
    
    return {handleChange, values, handleSubmit, errors};
}

export default useForm;