import "./footer.css";
import footerLogo from "../../images/forfooter.png";
import logo4footer from './logosvg.svg';
import { Link, NavLink } from 'react-router-dom';
import React from 'react';
// import {Box} from './footerstyles';

export default function Footer(){
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    return <>
      
        <div className="container-fluid px-4" id="fullFooter">
            <div className="row justify-content-center gx-5">
                <div className="col-12 col-md-3  align-items-center">
                    <img src={logo4footer} alt="Valis BioScience" />
                    <p className="footerText">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi amet nisl purus tellus turpis in ipsum tristique erat. Etiam nisl sed aliquet magna egestas. Magna rhoncus, egestas in libero. At purus.
                    </p>
                </div>
                <div className="col-12 col-md-2" id="quickLinks">
                    <h5>Quick Links</h5>
                    <p><NavLink to="/covid" className={({ isActive }) => (isActive ? 'flinks-active' : 'flinks')}>Covid-19</NavLink></p>
                    <p>What We Do</p>
                    <p><NavLink to="/conditions" className={({ isActive }) => (isActive ? 'flinks-active' : 'flinks')}>Terms & Conditions</NavLink></p>
                    <p><NavLink to="/privacypolicy" className={({ isActive }) => (isActive ? 'flinks-active' : 'flinks')}>Privacy Policy</NavLink></p>
                    <p><NavLink to="/privacyshield" className={({ isActive }) => (isActive ? 'flinks-active' : 'flinks')}>Privacy Shield</NavLink></p>
                </div>
                <div className="col-12 col-md-2 " id="services">
                <h5>Services</h5>
                    <p>ACAM</p>
                    <p>eCOA</p>
                    <p>ePRO</p>
                    <p>Rater Training</p>
                    <p>MHealth Apps</p>
                    <p>Remote Assessment Solution</p>
                </div>
                <div className="col-12 col-md-2" id="getinTouch">
                    <h5>Get In Touch</h5>
                    <NavLink to='/contactus' className="flinks"><p ><i class="bi bi-person-lines-fill" id="footerCU"></i>Contact Us</p></NavLink>
                    <p><i className="bi bi-mailbox2" id="footerMB"></i> PO Box 2054</p>
                   
                    <p><i className="bi bi-geo-alt-fill" id="footerLN"></i>San Francisco, CA, Berkeley <span id="CA">CA, and New York, NY.</span></p>
                   
                </div>
            </div>
            <hr className="footerDivider"/>
            <div className="row justify-content-center">
         
                <div className="col-6 col-md-4 offset-md-1" id="copyRight">
                <p>Copyright &copy; 2022. All Rights Reserved</p>
                </div>
                <div className="col-6 col-md-2">

                </div>
                <div className="col-12 col-lg-4 " id="footerIcons">
                <i className="bi bi-linkedin" id="footerPT" onClick={() => openInNewTab('https://www.linkedin.com/company/valisbioscience/about/')}></i>
                <i className="bi bi-twitter" id="footerTW"></i>
                
                </div>
            </div>

        </div>
        {/* <div className="fullFooter">
            <div className="logo4footer">
                <img src={footerLogo} alt="Valis BioScience" />
            </div>    
            <div className="footerText">
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi amet nisl purus tellus turpis in ipsum tristique erat. Etiam nisl sed aliquet magna egestas. Magna rhoncus, egestas in libero. At purus.
                </p>
            </div>
            <div className="quickLinks">
                <h5>Quick Links</h5>
                <p>Covid-19</p>
                <p>What We Do</p>
                <p>Terms & Conditions</p>
                <p>Privacy Policy</p>
                <p>Privacy Shield</p>
            </div>
            <div className="services">
                <h5>Services</h5>
                <p>Remote Assessment Solution</p>
                <p>MHealth Apps</p>
                <p>Rater Training</p>
                <p>Video Capture</p>
            </div>
            <div className="getinTouch">
                <h5>Get In Touch</h5>
                <p><i className="bi bi-mailbox2" id="footerMB"></i> PO Box 2054</p>
                <p><i className="bi bi-envelope-fill" id="footerEN"></i>help@valisbioscience.com</p>
                <p><i className="bi bi-geo-alt-fill" id="footerLN"></i>San Francisco, CA, Berkeley CA, and New York, NY.</p>
            </div>
            <hr className="footerDivider"/>
            <div className="copyRight">
                <p>Copyright &copy; 2022. All Rights Reserved</p>
            </div>
            <div className="footerIcons">
                <i className="bi bi-facebook" id="footerFB"></i>
                <i className="bi bi-instagram" id="footerIG"></i>
                <i className="bi bi-twitter" id="footerTW"></i>
                <i className="bi bi-pinterest" id="footerPT"></i>
            </div>
        </div> */}
    
    </>
}