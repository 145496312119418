import React from "react";
import Header1 from "../components/header1/header1";
import './blog1.css';
import section1 from './section1.jpg';
import typing from './type.jpg';
import rec1 from './Rectangle12.jpg';
import rec2 from './Rectangle13.jpg';
import rec3 from './Rectangle14.jpg';
import ellipse from "./Ellipse51.jpg";
import Footer from "../components/footer/footer";

export default function Blog1(){
    return<>
        <Header1 />
        {/* -------------------------------------------Section 1----------------------------------------------- */}
        <div className='container-fluid' id="bmainCoviddiv">
            
            <img src={section1} id="bmainSection" alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-7 " id="bsec1" >
                    <h1>Valis attends ACNP Annual Meeting</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-12 col-lg-4 " id="bhomeTocovid">
                <h6>Home {'>'}The Latest {'>'}  <span>Valis attends ACNP Annual Meeting-19</span></h6>
            </div>
            </div>
        </div>
        {/* ---------------------------------------------------blog para----------------------------------------- */}
        <div className="container" id="blogPara">
            <div className="row justify-content-evenly">
                <div className="col col-lg-6" id="underTyping">
                    <img src={typing} id="typing" alt="computer typing" />
                    <p>Last week Valis CEO Ivan Goldensohn attended the American College of Neuropsychopharmacology meeting in Orlando, Florida. The ACNP Meeting (more info at https://acnp.org/) is an invite-only annual meeting with a heavy focus on scientific content, and is one of the premier scientific meetings focused on Neurology and CNS research.</p>
                    <p>ACNP's content this year was exceptionally curated and included several topics, panels, and presentations on areas aligned with Valis' scientific and technological perspective. Content focused on the use of Artificial Intelligence, Digital Phenotyping, wearables, and digital tools stood out, and Valis was fortunate to be able to participate in a working group session on "Ethical considerations for the use of digital tools in research."</p>
                    <p>Mr. Goldensohn described the meeting in glowing terms, and noted, "ACNP is always a wonderful place to see friends from the research world and discuss scientific collaborations. It was a pleasure to run into new and old friends from NIH, NIMH, NIAAA, various top Universities, and pharma companies. The clear growth in key topics of interest to Valis made ACNP a real highlight of our conference attendance in 2019."</p>
                    <p>ACNP is Valis' last major conference for 2019. 2020 will be an exciting year for the company, with multiple posters and abstracts already accepted at several conferences, in addition to several planned major announcements. Valis' next events include JPM and the Biotech Showcase in San Francisco in January, and ISCTM in February, where Valis CSO Dr. Christian Yavorsky is chairing the working group on Measurement in Rapid Acting Antidepressants.</p>
                    <br />
                    <br />
                    <h3>Comments</h3>
                    <hr />
                    <div className="row">
                        <div className="col col-lg-2">
                            <img src={ellipse} alt="Ellipe" />
                        </div>
                        <div className="col">
                            <div class="mb-3">
                            
                            <textarea class="form-control" placeholder="Write a comment" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col col-lg-3">
                    <h3>Popular Posts</h3>
                    <hr />
                    <div className="row">
                        <div className="col col-lg-3">
                           <img src={rec1} id="smallImg" alt="rec1" />
                        </div>
                        <div className="col col-lg-9" id="popText">
                            <h5>A complete guide for your dropshipping package boxes</h5>
                            <p>December 20, 2021</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col  col-lg-3">
                        <img src={rec2} id="smallImg" alt="rec1" />
                        </div>
                        <div className="col" id="popText">
                            <h5>A complete guide for your dropshipping package boxes</h5>
                            <p>December 20, 2021</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col  col-lg-3">
                        <img src={rec3} id="smallImg" alt="rec1" />
                        </div>
                        <div className="col" id="popText">
                            <h5>A complete guide for your dropshipping package boxes</h5>
                            <p>December 20, 2021</p>
                        </div>
                    </div>
                    <h3>Tags</h3>
                    <hr />
                    <div class="sidebar-item-tags">
                    
                    
                      <ul>
                        <li><a href="#">Lifestyle</a></li>
                        <li><a href="#">Creative</a></li>
                        <li><a href="#">HTML5</a></li>
                        <li><a href="#">Inspiration</a></li>
                        <li><a href="#">Motivation</a></li>
                        <li><a href="#">PSD</a></li>
                        <li><a href="#">Responsive</a></li>
                      </ul>
                    </div>
                 
                </div>
            </div>
        </div>
        <div className="blog1Footer">
            <Footer />
        </div>
    </>
}