import './header1.css';
import valisLogo from "../../images/layer3.png";
import logosvg from './logosvg.svg';
import { Link, NavLink } from 'react-router-dom';
import styles from './header1.css';
import React from 'react';
// import {Link} from 'react-router-dom';

export default function Header1(){

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
   
    return<>
        <div className='container-fluid' id='header'>
            <div className='row justify-content-center' id="row1">
                <div className='col-3 col-lg-2' id="PO">
                    <p>
                    <i className="bi bi-mailbox2" id="mailbox1"></i> PO Box 2054 
                    </p>
                </div>
               
                <div className='col-6 col-lg-4' id="address">
                    <p>
                    <i className="bi bi-geo-alt-fill" id="location1"></i>San Francisco, CA, Berkeley CA, and New York, NY.
                    </p>
                </div>
                <div className='col-2 col-lg-4 offset-lg-2' id="icons">
                    <i className="bi bi-linkedin" id="pt1" onClick={() => openInNewTab('https://www.linkedin.com/company/valisbioscience/about/')}></i>
                    <i className="bi bi-twitter" id="tw1"></i>
                </div>
            </div>
            <div className='row justify-content-center ' id="row2">

                <div className='col col-lg-4 offset-lg-1  ' id="logo">
                    <img src={logosvg} alt="Valis BioScience" />
                </div>
                {/* <div className='col-12 col-lg-4 ' > */}
                    {/* <input id="search" type="text" placeholder="SEARCH" />
                    {/* <input  type="text" 
                                className="form-control" 
                                id="search" 
                                placeholder="SEARCH" /> */}
                    {/* <button id="searchButton">
                    <i className="bi bi-search"></i> 
                    </button> */}
                
                {/* </div> */}
                <div className='col-sm-12 col-lg-3 offset-lg-2 d-xs-none ' >
                <input type="button" id="lbutton" value="Login" />
                <Link to="/contactus"><input type="button" id="cbutton" value="Contact Us" /></Link>
                </div>
            </div>
            <hr className="lineforHeader" />
            {/* <div className="row justify-content-center  " id="navLinks">
                
                    
                <nav >
                
                    <ul >
                        {/* <li>Home</li>
                        <li>COVID-19</li> 
                        <div className="col-1" ><li><Link to="/">HOME</Link></li></div>
                        <div className="col-1" ><li><Link to="/covid">COVID-19</Link></li></div>
                        <div className="col-1" ><li>ABOUT US</li></div>
                        <div className="col-2" ><li>THERAPEUTIC AREAS</li></div>
                        <div className="col-1" ><li>TECHNOLOGY</li></div>
                        <div className="col-1" ><li>THE LATEST</li></div>
                        <div className="col-1" ><li>CAREERS</li></div>
                        
                    </ul>
                   
                </nav>
               
            </div> */}
            
            <div className='row justify-content-center' id="navLinks">
            <div className='col-lg-8'>

            <ul className="nav  " id="actualLinks">

            <li >
                <NavLink  to="/"  className={({ isActive }) => (isActive ? 'links-active' : 'links')}>HOME</NavLink>
            </li>
            <li >
                <NavLink  exact to="/covid" className={({ isActive }) => (isActive ? 'links-active' : 'links')}>COVID-19</NavLink>
            </li>
            <li >
                <NavLink  exact to="/aboutUs" className={({ isActive }) => (isActive ? 'links-active' : 'links')}>ABOUT US</NavLink>
            </li>
            <li >
            {/* <select name="cars" value="THERAPEUTIC AREAS" id="cars">
                <option value="THERAPEUTIC AREAS">THERAPEUTIC AREAS</option>
                <option value="saab">Saab</option>
                <option value="opel">Opel</option>
                <option value="audi">Audi</option>
            </select> */}
            THERAPEUTIC AREAS
            </li>
            <li >
                <NavLink  exact to="/technology" className={({ isActive }) => (isActive ? 'links-active' : 'links')}>TECHNOLOGY</NavLink>
            </li>
            <li >
                <NavLink  exact to="/latest" className={({ isActive }) => (isActive ? 'links-active' : 'links')}>THE LATEST</NavLink>
            </li>
            <li >
                <NavLink  exact to="/careers" className={({ isActive }) => (isActive ? 'links-active' : 'links')}>CAREERS</NavLink>
            </li>
           
            </ul>
            </div>
            </div>
        </div>
    </>
}