import React from "react";
import Footer from "../components/footer/footer";
import Header1 from "../components/header1/header1";
import './aboutUs.css';
import useForm from './useForm';
import validate from './validate';

// Number 1
import number1img from './number1.jpg';


// Section 1
import section1 from './section1.jpg';

//Number 3
import n3bg from './n3bg.jpg';
import n3phones from './n3phones.jpg';

// Number 2
import phone from './phone.jpg';

// Number 4
import number4 from './number4.jpg';

// Number 5
import reduce from './reduce.jpg';
import increase1 from './increase1.jpg';
import increase2 from './increase2.jpg';
import increase3 from './increase3.jpg';
import democratize from './democratize.jpg';


export default function AboutUs(){
    const {handleChange, values,handleSubmit, errors } = useForm(validate);
    return<>
        <Header1 />
        {/* -------------------------------------Section 1--------------------------------------- */}
        <div className='container-fluid' id="aboutsection1">
            
            <img src={section1} alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-4 " id="aboutsec1" >
                    <h1>About Us</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center ' >
            <div className="col-12 col-lg-4 r"id="aboutsec2">
                    <h4>Creative science-driven solutions</h4>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-3 col-lg-1 " id="abouthomeTocovid">
                <h6>Home {'>'} <span>About us</span></h6>
            </div>
            </div>
        </div>

        {/* -----------------------------------------------Bar Section------------------------------------- */}
        <div className='container-fluid' id="bar">
        <div className='row gx-5' id="barRow">
            <div className='col-6 col-lg-auto offset-md-1' id="b1">
                <h5>eCOA and ePRO</h5>
                {/* <hr id="barDivider1" /> */}
            </div>
           
            <div className='col-6 col-lg-2 ' id="b1">
                <h5>Endpoints and Assessment Training</h5>
                {/* <hr id="barDivider2" /> */}
            </div>
           
            <div className='col-6 col-lg-auto ' id="b1">
                <h5>Video Capture</h5>
                {/* <hr id="barDivider3" /> */}
            </div>
           
            <div className='col-6 col-lg-2' id="b1">
                <h5>Scientific Consultation Services</h5>
                {/* <hr id="barDivider4" /> */}
            </div>
           
            <div className='col-6 col-lg-2' id="b1">
                <h5>Digital Phenotyping</h5>
            </div>
        </div>
    </div>
    {/* ------------------------------------------------Number 1------------------------------------------- */}
        <div><h1 id="digits">01</h1></div>
        <div className='container' >
            <div className='row justify-content-center' id="number1">
                <div className='col-12 col-lg-5 offset-md-1'>
                    <h3 id="n1">Training and Education</h3>
                    <p id="n2">Rater Training</p>
                    <p id="n3">Subject & Caregiver Education</p>
                    <h5 id="n4">SME led rater training programs with custom modules built to address factors that may impact study data quality.</h5>
                    
                        <li id="n5-1">Ensures proper scale administration.</li>
                        <li id="n5-2">Virtual and in-person training available, including eCertification.</li>
                        <li id="n5-3">Expert in-house trainers.</li>
                        <li id="n5-4">Valis can provide automated training modules and refresher training online through its training portal.</li>
                        <li id="n5-5">Customized to address study specific geographic, political, and cultural factors.</li>
                    
                </div>
                <div className='col-12 col-md-5' id="meeting">
                    <img src={number1img} alt="Meeting Image" />
                </div>
            </div>
        </div>
        {/* ---------------------------------------------Number 2-------------------------------------------- */}
        
        <div className="container-fluid" id="n22">
        <img src={phone} id="phoneImg" className=".d-sm-none .d-lg-block" alt="iPhone image" />
        {/* <div><h1 id="digits2">02</h1></div> */}
            <div className="row justify-content-end">
            
           
                <div className="col-12 col-lg-8"  id="number2">
                
                    <h2>Valis ACAM</h2>
                    <p>ACAM is a streamlined hardware (provisioned device) and software (BYOD) solution for secure, rater-friendly recording of assessment visits.
                    <br /><br />Recordings can easily be assigned, reviewed, and independently scored by Valis or a designated third party through the ACAM portal.
                    <br /><br /> Video monitoring significantly improves data quality and protocol adherence in trials.    
                    <br /><br /> ACAM is regulatory compliant, custom tailored and branded per use, and can be deployed on aggressive timelines.</p>
                </div>
            </div>
        </div>

        {/* -----------------------------------------------Number 3--------------------------------------- */}
        <div className="container-fluid">
            <img src={n3bg}  id="n3bg" alt="number 3 background" />
            <div><h1 id="digits3">03</h1></div>
            <div className="row justify-content-center">
                <div className="col-6 col-lg-9">
                    <h2 id="n3heading">eDiary, ePRO, eRecovery</h2>
                    <p id="n3p">Custom eDiary solutions deployed in weeks, not months.
                    <br />Bespoke outcome measures and ePRO applications designed by SME clinicians with available validation support on new measures. 
                    <br />Training applications, mobile refresher courses and eCertification
                    <br />eRecovery applications and preventative measures with automated interventions.</p>
                </div>
                <div className="col-6 col-lg-5 offset-lg-5">
                    <img src={n3phones} id="n3phones" alt="n3phones" />
                </div>
            </div>
        </div>
        {/* ----------------------------------------------Number 4------------------------------------------- */}
        <div className="container-fluid">
            <div><h1 id="digits4">04</h1></div>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-4">
                    <img src={number4} id="number4img" alt="number4 image" />
                </div>
                <div className="col-12 col-lg-4">
                    <h2 id="n4heading">Endpoint selection, management & development</h2>
                    <p id="n4p">Remote Assessment Solutions
                    <br />Valis is actively supporting </p>
                </div>
            </div>
        </div>

        {/* -----------------------------------------------Number 5---------------------------------------------- */}
        <div className="container-fluid" id="n5bg">
           <div className="row">
               <div className="col" id="goals">
                   <h2>Goals</h2>
               </div>
           </div>
           <div className="row justify-content-center"  id ="goalsDetail" >
               <div className="col-sm-4 col-lg-2 offset-lg-1">
               <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 18.75L18.75 12.5H12.5V0H6.25V12.5H0L6.25 18.75L9.375 21.875L12.5 18.75Z" fill="#EB5757"/>
                <path d="M43.75 0H37.5V12.5H31.25L37.5 18.75L40.625 21.875L43.75 18.75L50 12.5H43.75V0Z" fill="#EB5757"/>
                <path d="M28.125 15.625H21.875V28.125H15.625L21.875 34.375L25 37.5L28.125 34.375L34.375 28.125H28.125V15.625Z" fill="#EB5757"/>
                <path d="M6.25 50C7.97589 50 9.375 48.6009 9.375 46.875C9.375 45.1491 7.97589 43.75 6.25 43.75C4.52411 43.75 3.125 45.1491 3.125 46.875C3.125 48.6009 4.52411 50 6.25 50Z" fill="#EB5757"/>
                <path d="M18.75 50C20.4759 50 21.875 48.6009 21.875 46.875C21.875 45.1491 20.4759 43.75 18.75 43.75C17.0241 43.75 15.625 45.1491 15.625 46.875C15.625 48.6009 17.0241 50 18.75 50Z" fill="#EB5757"/>
                <path d="M31.25 50C32.9759 50 34.375 48.6009 34.375 46.875C34.375 45.1491 32.9759 43.75 31.25 43.75C29.5241 43.75 28.125 45.1491 28.125 46.875C28.125 48.6009 29.5241 50 31.25 50Z" fill="#EB5757"/>
                <path d="M43.75 50C45.4759 50 46.875 48.6009 46.875 46.875C46.875 45.1491 45.4759 43.75 43.75 43.75C42.0241 43.75 40.625 45.1491 40.625 46.875C40.625 48.6009 42.0241 50 43.75 50Z" fill="#EB5757"/>
                </svg>

                   <h5>REDUCE</h5>
                   <p>the cost of pharmaceutical research and treatment</p>
               </div>
               <div className="col-sm-4 col-lg-2" >
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1074 18.75L19.3574 25H13.1074V37.5H6.85742V25H0.607422L6.85742 18.75L9.98242 15.625L13.1074 18.75Z" fill="#038AF6"/>
                    <path d="M44.3574 37.5H38.1074V25H31.8574L38.1074 18.75L41.2324 15.625L44.3574 18.75L50.6074 25H44.3574V37.5Z" fill="#038AF6"/>
                    <path d="M28.7324 21.875H22.4824V9.375H16.2324L22.4824 3.125L25.6074 0L28.7324 3.125L34.9824 9.375H28.7324V21.875Z" fill="#038AF6"/>
                    <path d="M6.85742 50C8.58331 50 9.98242 48.6009 9.98242 46.875C9.98242 45.1491 8.58331 43.75 6.85742 43.75C5.13153 43.75 3.73242 45.1491 3.73242 46.875C3.73242 48.6009 5.13153 50 6.85742 50Z" fill="#038AF6"/>
                    <path d="M19.3574 50C21.0833 50 22.4824 48.6009 22.4824 46.875C22.4824 45.1491 21.0833 43.75 19.3574 43.75C17.6315 43.75 16.2324 45.1491 16.2324 46.875C16.2324 48.6009 17.6315 50 19.3574 50Z" fill="#038AF6"/>
                    <path d="M31.8574 50C33.5833 50 34.9824 48.6009 34.9824 46.875C34.9824 45.1491 33.5833 43.75 31.8574 43.75C30.1315 43.75 28.7324 45.1491 28.7324 46.875C28.7324 48.6009 30.1315 50 31.8574 50Z" fill="#038AF6"/>
                    <path d="M44.3574 50C46.0833 50 47.4824 48.6009 47.4824 46.875C47.4824 45.1491 46.0833 43.75 44.3574 43.75C42.6315 43.75 41.2324 45.1491 41.2324 46.875C41.2324 48.6009 42.6315 50 44.3574 50Z" fill="#038AF6"/>
                    </svg>
                   <h5>INCREASE</h5>
                   <p>efficiency of treatment and research</p>
               </div>
               <div className="col-sm-4 col-lg-2">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1074 18.75L19.3574 25H13.1074V37.5H6.85742V25H0.607422L6.85742 18.75L9.98242 15.625L13.1074 18.75Z" fill="#038AF6"/>
                    <path d="M44.3574 37.5H38.1074V25H31.8574L38.1074 18.75L41.2324 15.625L44.3574 18.75L50.6074 25H44.3574V37.5Z" fill="#038AF6"/>
                    <path d="M28.7324 21.875H22.4824V9.375H16.2324L22.4824 3.125L25.6074 0L28.7324 3.125L34.9824 9.375H28.7324V21.875Z" fill="#038AF6"/>
                    <path d="M6.85742 50C8.58331 50 9.98242 48.6009 9.98242 46.875C9.98242 45.1491 8.58331 43.75 6.85742 43.75C5.13153 43.75 3.73242 45.1491 3.73242 46.875C3.73242 48.6009 5.13153 50 6.85742 50Z" fill="#038AF6"/>
                    <path d="M19.3574 50C21.0833 50 22.4824 48.6009 22.4824 46.875C22.4824 45.1491 21.0833 43.75 19.3574 43.75C17.6315 43.75 16.2324 45.1491 16.2324 46.875C16.2324 48.6009 17.6315 50 19.3574 50Z" fill="#038AF6"/>
                    <path d="M31.8574 50C33.5833 50 34.9824 48.6009 34.9824 46.875C34.9824 45.1491 33.5833 43.75 31.8574 43.75C30.1315 43.75 28.7324 45.1491 28.7324 46.875C28.7324 48.6009 30.1315 50 31.8574 50Z" fill="#038AF6"/>
                    <path d="M44.3574 50C46.0833 50 47.4824 48.6009 47.4824 46.875C47.4824 45.1491 46.0833 43.75 44.3574 43.75C42.6315 43.75 41.2324 45.1491 41.2324 46.875C41.2324 48.6009 42.6315 50 44.3574 50Z" fill="#038AF6"/>
                    </svg>
                   <h5>INCREASE</h5>
                   <p>the power, quality, and reproducibility of research</p>
               </div>
               <div className="col-sm-4 col-lg-2">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1074 18.75L19.3574 25H13.1074V37.5H6.85742V25H0.607422L6.85742 18.75L9.98242 15.625L13.1074 18.75Z" fill="#038AF6"/>
                    <path d="M44.3574 37.5H38.1074V25H31.8574L38.1074 18.75L41.2324 15.625L44.3574 18.75L50.6074 25H44.3574V37.5Z" fill="#038AF6"/>
                    <path d="M28.7324 21.875H22.4824V9.375H16.2324L22.4824 3.125L25.6074 0L28.7324 3.125L34.9824 9.375H28.7324V21.875Z" fill="#038AF6"/>
                    <path d="M6.85742 50C8.58331 50 9.98242 48.6009 9.98242 46.875C9.98242 45.1491 8.58331 43.75 6.85742 43.75C5.13153 43.75 3.73242 45.1491 3.73242 46.875C3.73242 48.6009 5.13153 50 6.85742 50Z" fill="#038AF6"/>
                    <path d="M19.3574 50C21.0833 50 22.4824 48.6009 22.4824 46.875C22.4824 45.1491 21.0833 43.75 19.3574 43.75C17.6315 43.75 16.2324 45.1491 16.2324 46.875C16.2324 48.6009 17.6315 50 19.3574 50Z" fill="#038AF6"/>
                    <path d="M31.8574 50C33.5833 50 34.9824 48.6009 34.9824 46.875C34.9824 45.1491 33.5833 43.75 31.8574 43.75C30.1315 43.75 28.7324 45.1491 28.7324 46.875C28.7324 48.6009 30.1315 50 31.8574 50Z" fill="#038AF6"/>
                    <path d="M44.3574 50C46.0833 50 47.4824 48.6009 47.4824 46.875C47.4824 45.1491 46.0833 43.75 44.3574 43.75C42.6315 43.75 41.2324 45.1491 41.2324 46.875C41.2324 48.6009 42.6315 50 44.3574 50Z" fill="#038AF6"/>
                    </svg>
                   <h5>INCREASE</h5>
                   <p>patient safety and centricity in research and treatment</p>
               </div>
               <div className="col-sm-4 col-lg-2">
                    <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.52607 25.9492C9.52607 17.3887 16.5301 10.3846 25.0906 10.3846H27.6847C28.2035 10.3846 28.7872 10.3846 29.306 10.4495L30.603 5.391C29.6303 5.2613 28.6575 5.13159 27.6847 5.13159H25.0906C13.6118 5.13159 4.33789 14.4055 4.33789 25.8843C4.33789 27.4408 4.53245 28.9324 4.85671 30.424L9.85033 29.1269C9.65577 28.0893 9.52607 27.0516 9.52607 25.9492Z" fill="#6E943E"/>
                    <path d="M43.2488 25.9492C43.2488 34.5097 36.2448 41.5137 27.6843 41.5137H25.0902C24.5714 41.5137 23.9877 41.5137 23.4689 41.4489L22.1719 46.5073C23.1447 46.637 24.1174 46.7667 25.0902 46.7667H27.6843C39.1632 46.7667 48.437 37.4929 48.437 26.014C48.437 24.4576 48.2425 22.966 47.9182 21.4744L42.9246 22.7714C43.1191 23.809 43.2488 24.8467 43.2488 25.9492Z" fill="#6E943E"/>
                    <path d="M8.87669 38.0117L1.54839 45.2752C0.0567871 46.7668 0.0567871 49.2311 1.54839 50.7227C3.03999 52.2143 5.50438 52.2143 6.99598 50.7227L14.3243 43.4593L17.956 47.091L22.4957 29.8403L5.24497 34.38L8.87669 38.0117Z" fill="#6E943E"/>
                    <path d="M45.7122 1.11072L38.4487 8.43903L34.817 4.8073L30.2773 22.058L47.528 17.5183L43.8963 13.8866L51.1598 6.62316C52.6514 5.13156 52.6514 2.66718 51.1598 1.17558C49.6682 -0.380877 47.2686 -0.380878 45.7122 1.11072Z" fill="#6E943E"/>
                    </svg>
                   <h5>DEMOCRATIZE</h5>
                   <p>the cost of pharmaceutical research and treatment</p>
               </div>
           </div>
        </div>
        {/* -----------------------------------------------Number 6--------------------------------------- */}
        <div className="container-fluid">
            <div className="row">
                
                <div className="col offset-lg-2" >
                    <h2 id="n6h2">Values</h2>
                </div>
            </div>
            <div className="row justify-content-center " id="n6r1">
                <div className="col-6 col-lg-4" >
                    <h4>01: Help</h4>
                    <h6>Medical research should help people.</h6>
                    <p>Patient-centricity is key. Our technology, and the services we provide are engineered and executed to protect, improve, and save lives.</p>
                </div>
                <div className="col-6 col-lg-4" id="protect">
                    <h4>02: Protect</h4>
                    <h6>Patient Safety is paramount.</h6>
                    <p>Ensuring patient safety and centricity is always the first priority.</p>
                </div>
            </div>
            <div className="row justify-content-center" id="n6r2">
                <div className="col-6 col-lg-4">
                    <h4>03: Advance</h4>
                    <h6>Advanced Medical research with technology is an imperative.</h6>
                    <p>Without serious disruption our world will continue to waste billions of dollars producing low quality research. Better tech in drug discovery can save our society billions of dollars and help find new treatments faster and in a safer manner.</p>
                </div>
                <div className="col-6 col-lg-4">
                    <h4>04: Succeed Ethically</h4>
                    <h6>Success and moral values are not mutually exclusive..</h6>
                    <p>Profitability is important, as is advancing medicine and medical research and improving lives. Both must be considered in all business decisions.</p>
                </div>
            </div>
        </div>
        {/* -----------------------------------------------Number 7------------------------------------------ */}
        <div className="container-fluid py-5" id="n7bg">
            <div className="row ">
                <div className="col" id="formDesc">
                    <h3 >Please contact us using the form below.</h3>
                </div>
            </div>
            <form className="row justify-content-center "  id="form1">
                <div className="col-12 col-lg-2 ">
                <input  type="text" 
                                name="username" 
                                className="form-control" 
                                value={values.username}
                                onChange={handleChange} 
                                id="aboutName"
                                placeholder="Name" />
                        {errors.username && <p id="anameErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.username}</p>}
                        
                </div>
                <div className="col-12 col-lg-2">
                <input  type="text" 
                                name="email"
                                className="form-control" 
                                value={values.email}
                                onChange={handleChange} 
                                id="aboutEmail"
                                placeholder="Email" />
                    {errors.email && <p id="aemailErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.email}</p>} 

                </div>
            </form>
            <form className="row justify-content-center " autoComplete="off" id="form2">
                <div className="col-12 col-lg-4">
                <input  type="text"
                                name="subject" 
                                className="form-control"
                                value={values.subject}
                                onChange={handleChange} 
                               
                                placeholder="Subject" />
                    {errors.subject && <p id="asubjectErrors"><i className="bi bi-exclamation-octagon-fill" />&nbsp;&nbsp;{errors.subject}</p>} 

                </div>
            </form>
            <form className="row justify-content-center " autoComplete="off" id="form3">
                <div className="col-12 col-lg-4 "  >
                <textarea className="form-control" 
                          rows="3"
                          name="comments" 
                          placeholder='Comments'
                          value={values.comments}
                          onChange={handleChange} 
                ></textarea>
                </div>
            </form>
            <form className="row justify-content-end " onSubmit={handleSubmit} id="form4">
                <div className="col-12 col-lg-9">
                    <button type="submit" id="form4-1" className="btn btn-primary">SUBMIT NOW</button>
                </div>
            </form>
        </div>
    <div className="aboutFooter">
    <Footer />
    </div>
    </>
}