export default function validate(values){
    let errors =[];

    if(!values.username.trim()){
        errors.username = "Please fill in your name"
    }

    else if(values.username.length<4){
        errors.username="Name must not be less than 4 characters"
    }

    if(!values.subject.trim()){
        errors.subject = "Please fill in subject"
    }

    if(!values.comments.trim()){
        errors.comments = "Please enter in comments in detail"
    }

    else if(values.comments.length<20){
        errors.comments = "Comments should be more than 20 characters"
    }

    if(!values.email){
        errors.email = "Please enter your email"
    }
    else if(!/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(values.email)){
        errors.email = "Email is invalid";
    }
    return errors;
}

