import Header1 from '../components/header1/header1';
import section1 from './section1.jpg';
import Card1 from './card1.jpg';
import Card2 from './card2.jpg';
import Card3 from './card3.jpg';
import Card4 from './card4.jpg';
import './latest.css';
import Footer from '../components/footer/footer';
import {Link} from "react-router-dom";


export default function Latest(){
    return<>
        <Header1 /> 
        {/* -------------------------------------------Section 1------------------------------------- */}
        <div className='container-fluid' id="latestsection1">
            
            <img src={section1} alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-3 " id="latestsec1" >
                    <h1>The Latest</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-1 " id="latesthomeTocovid">
                <h6>Home {'>'} <span>The Latest</span></h6>
            </div>
            </div>
        </div>
        
        {/* ---------------------------------------------Cards Section------------------------------------------------ */}
        <div className='container-fluid '>
        <div className="row justify-content-center gy-3 " id="latestCards">
            <div className="col-12 col-lg-5">
                <Link to="/blog1" id="toBlog1">
                <div className="card" id="lc1">
                <img src={Card1} className="card-img-top" alt="Valis attends ACNP Annual Meeting" />
                <div className="card-body">
                    <h5 className="card-title">Valis attends ACNP Annual Meeting</h5>
                    <p className="card-text">Last week Valis CEO Ivan Goldensohn attended the American College of Neuropsychopharmacology meeting in Orlando, Florida. The ACNP...</p>
                    
                </div>
                <div class="card-footer" id="cf1">
                    <p id="cfDates"><i class="bi bi-calendar3"></i>&nbsp;<span id="latestDate">March 1, 2022</span> &nbsp;&nbsp;&nbsp;&nbsp;&emsp;&nbsp;
                       <i class="bi bi-person-fill"></i>&nbsp;<span id="latestDate">Valis Bioscience</span>
                    </p>
                </div>
                </div>
                </Link>
            </div>
            <div className="col-12 col-lg-5">
                <div className="card" id="lc2">
                <img src={Card2} className="card-img-top" alt="Valis Joins CNS Leaders at 10th Annual CNS Su..." />
                <div className="card-body">
                    <h5 className="card-title">Valis Joins CNS Leaders at 10th Annual CNS Su...</h5>
                    <p className="card-text">Valis Bioscience CEO Ivan Goldensohn attended the 10th annual CNS Summit in Boca Raton in late October. A long-time supporter of CNS...</p>
                    
                </div>
                <div class="card-footer" id="cf2">
                    <p  id="cfDates"><i class="bi bi-calendar3"></i>&nbsp;<span id="latestDate">March 1, 2022</span> &nbsp;&nbsp;&nbsp;&nbsp;&emsp;&nbsp;
                       <i class="bi bi-person-fill"></i>&nbsp;<span id="latestDate">Valis Bioscience</span>
                    </p>
                </div>
                </div>
            </div>

            
            <div className="col-12 col-lg-5">
                <div className="card" id="lc3">
                <img src={Card3} className="card-img-top" alt="Valis co-founder speaks at KRIYA Conference" />
                <div className="card-body">
                    <h5 className="card-title">Valis co-founder speaks at KRIYA Conference</h5>
                    <p className="card-text">Valis Co-founder and CSO Dr. Christian Yavorsky spoke last weekend weekend at the Kriya Conference in Northern California. Alongside...</p>
                   
                </div>
                <div class="card-footer" id="cf3">
                    <p  id="cfDates"><i class="bi bi-calendar3"></i>&nbsp;<span id="latestDate">March 1, 2022</span> &nbsp;&nbsp;&nbsp;&nbsp;&emsp;&nbsp;
                       <i class="bi bi-person-fill"></i>&nbsp;<span id="latestDate">Valis Bioscience</span>
                    </p>
                </div>
                </div>
            </div>

            <div className="col-12 col-lg-5">
                <div className="card" id="lc4">
                <img src={Card4} className="card-img-top" alt="Christian Yavorsky (CSO) Co-Chairs the Rapid A..." />
                <div className="card-body">
                    <h5 className="card-title">Christian Yavorsky (CSO) Co-Chairs the Rapid A...</h5>
                    <p className="card-text">Valis is proud to announce that our Chief Scientific Officer and co-founder Dr. Christian Yavorsky has been selected as the co-chair of...</p>
                    
                </div>
                <div class="card-footer" id="cf4">
                    <p  id="cfDates"><i class="bi bi-calendar3"></i>&nbsp;<span id="latestDate">March 1, 2022</span> &nbsp;&nbsp;&nbsp;&nbsp;&emsp;&nbsp;
                       <i class="bi bi-person-fill"></i>&nbsp;<span id="latestDate">Valis Bioscience</span>
                    </p>
                </div>
                </div>
            </div>
        </div>
        </div>
    <div className='latestFooter'>
    <Footer />
    </div>
    </>
}