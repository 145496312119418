// import logo from './logo.svg';
import './App.css';
// import Header from './components/header/header.js';
// import Header1 from './components/header1/header1.js';
// import Footer from './components/footer/footer.js';
// import Welcome from './components/welcome/welcome.js';
// import Services from './components/services/services.js';
// import It_Services from './components/it_services/it_services.js';
// import Clients from './components/clients/clients.js';
// import Contact from './components/contact/contact.js';
// import HappyClients from './components/happyclients/happyclients';
// import MainSection from './covid19/mainSection/mainSection';
// import PicOnly from './covid19/piconly/piconly';
// import Section3 from './covid19/section3/section3';
// import Cards from './covid19/cards/cards';
// import Section1 from './aboutUs/section1/section1';
// import Bar from './aboutUs/bar/bar';
// import Number1 from './aboutUs/number1/number1';
import Covid from './covid19/covid';
import Home from './components/home';
import {Route, Routes} from "react-router-dom";
import AboutUs from './aboutUs/aboutUs';
import Technology from './technology/technology';
import Latest from './latest/latest';
import ContactUs from './contactus/contactus';
import PrivacyPolicy from './privacypolicy/privacypolicy';
import Conditions from './conditions/conditions';
import Header1 from './components/header1/header1';
import Footer from './components/footer/footer';
import Careers from './careers/careers';
import PrivacyShield from './privacyshield/privacyshield';
import PrivacyShield2 from './privacyshield/privacyshield2';
import Blog1 from './latest/blog1';

function App() {
  return (
    <div className="App">
    
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route  path="/home" element={<Home />} /> */}
            
          <Route path="/covid" element={<Covid />}/>
          <Route path="/aboutUs" element={<AboutUs />}/>
          <Route path="/technology" element={<Technology />}/>
          <Route path="/latest" element={<Latest />}/>
          <Route path="/contactus" element={<ContactUs />}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
          <Route path="/conditions" element={<Conditions />}/>
          <Route path="/careers" element={<Careers />}/>
          <Route path="/privacyshield" element={<PrivacyShield />}/>
          <Route path="/privacyshield2" element={<PrivacyShield2 />}/>
          <Route path="/blog1" element={<Blog1 />}/>
        </Routes>
   
    </div>
  );
}

export default App;
