import Header1 from '../components/header1/header1';
import section1 from './section1.jpg';
import './privacypolicy.css';
import Footer from '../components/footer/footer';

export default function PrivacyPolicy(){
    return<>
        <Header1 />
        {/* -----------------------------------------------Section 1--------------------------------------- */}
        <div className='container-fluid' id="asection1">
            
            <img src={section1} alt="Privacy Policy" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-3 " id="asec1" >
                    <h1>Privacy Policy</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-12 col-lg-1" id="ahomeTocovid">
                <h6>Home {'>'} <span>Privacy Policy</span></h6>
            </div>
            </div>
        </div>
        {/* -------------------------------------------------Privacy Text------------------------------------------ */}
        <div className='container-fluid' id="cont">
            <div className='row'>
                <div className='col-12 col-lg-9'>
                    <h1>How we protect your privacy</h1><br />
                    <p>At Valis, data privacy is important.</p>
                    <p>This Privacy Notice describes how Valis Biosciences, Inc. (“Valis”, “we”, “us”, or “our”)  may collect, use, and share personal information that you and others provide us through our website at www.valisbioscience.com (“Site”).</p>
                    <h4>Information Collection</h4>
                    <p>When using our Site, you may voluntarily provide personal information about yourself.  This may include information such as your name, contact information, or additional information you choose to provide to us.  For example, we may collect this information when you send us an inquiry.  We (or third parties as described below) may also collect technical information about your visit to our Site, such as your domain name, IP address, browser type, device identifier, activities on the Site, and date and time of your visit.</p>
                    <h4>Information Use</h4>
                    <p>We may use the personal and technical information we collect for any lawful purpose.  These purposes include, but are not limited to, the following:</p>
                    <h5>To provide our Site;</h5>
                    
                        <li>To respond to your inquiries and requests;</li>
                        <li>To communicate with website visitors and others;</li>
                        <li>To provide updates, surveys, and other content that may be of interest to you;</li>
                        <li>For business purposes, such as analytics, research, advertising and marketing, payment processing, and operational purposes;</li>
                        <li>To maintain, operate, customize, and improve the Site;</li>
                        <li>To comply with law enforcement and maintain the security of our Site; or</li>
                        <li>As otherwise disclosed at the time of collection or use.</li>
                    
                    <h4>Information Sharing and Disclosure</h4>
                    <p>We may share the personal information we have collected through the Site with other parties including, to the extent permitted by law:</p>
                  
                        <li>Third party vendors who process data on our behalf, email service providers who facilitate our email communications, and web hosting providers who track and analyze our website performance and related analytics;</li>
                        <li>Third parties who provide products and services that we think will be of interest to you;</li>
                        <li>With partners and other third parties for use in connection with business activities;</li>
                        <li>As part of a sale, merger or acquisition, or other transfer of all or part of our assets including as part of a bankruptcy proceeding;</li>
                        <li>Pursuant to a subpoena, court order, or other legal process or as otherwise required or requested by law, regulation, or government and oversight authorities, or to protect our rights or the rights or safety of third parties, including in connection with a health or safety emergency and in connection with disciplinary proceedings; or</li>
                        <li>With your consent or as otherwise disclosed at the time of data collection or sharing.</li>
                    
                    <br />
                    <p>The Site also includes links to third-party websites where you may be able to submit personal information directly to those third parties.  Please see the section on “Third-Party Links” below for more information. We may share technical information, as well as any other information that has been de-identified or aggregated so that it does not identify you personally, without restriction.</p>
                    <h4>Analytics</h4>
                    <p>We may work with third parties that collect data about your use of the Site and our other online services, and other sites over time, for non-advertising purposes.  For example, the Site uses Google Analytics to improve the performance of the Site and for other analytics purposes.  For more information about how Google collects and uses data when you use our Site, visit https://www.google.com/policies/privacy/partners/, and to opt out of Google Analytics, visit https://tools.google.com/dlpage/gaoptout/.</p>
                    <h4>Third-Party Tools and Links</h4>
                    <p>The Site may provide links to third-party websites or apps.  The Site may also include integrated social media tools or “plug-ins,” such as social networking tools offered by third parties.  If you use these tools to share personal information or you otherwise interact with social media features on the Site, those social media companies may collect information about you and may use and share such information in accordance with your account settings, including by sharing such information with the general public.  Your interactions with third-party companies and your use of their features are governed by the privacy policies of the companies that provide those features.  We encourage you to carefully read the privacy policies of these companies.</p>
                    <h4>Data Security</h4>
                    <p>When Valis collects information it follows generally-accepted best practices and industry standards to protect the submitted data and meet HIPAA privacy standards. Valis systems and products use advanced encryption, additional security features, and role management and authentification procedures to maintain the security of information restrict unauthorized access.</p>
                    <h4>Your Choices</h4>
                    <p>To update your contact information or change your communication preferences, including to stop email, telephonic, or mail communications from us, please contact us through the methods provided in the “Contact Us” section below.  You may also unsubscribe from our email communications by using the link provided in the email. </p>
                    <h4>Changes to Our Privacy Notice</h4>
                    <p>If our information practices change, we will post these changes on this page.  We encourage you to visit this page periodically to learn of any updates.</p>
                    <h4>Contact Us</h4>
                    <p>If you have questions, comments, or concerns about this Privacy Notice, please contact us, in our role as data controller, at:</p>
                    <p>2111 San Pablo Avenue, PO Box 2054 Berkeley California, 94702</p>
                    <p>1-925-338-0000</p>
                    <p>Privacy@valisbioscience.com</p>
                    <h4>Representations/Liability</h4>
                    <p>Valis makes no representations about the content of the information found on this website. The information presented on this website is provided to you “AS IS,” WITHOUT ANY WARRANTY, IMPLIED OR EXPRESSED, INCLUDING BY WAY OF EXAMPLE BUT WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR OTHERWISE. </p>
                    <br /><p>Under no circumstances shall Valis assume liability for the use or interpretation by you of information found on this website; rather, this will be your responsibility. </p>
                    <br /><p>Valis expressly disclaims liability for any direct, indirect, incidental, consequential or special damages arising out of your visit to this website and/or the information contained on this website, even if Valis is proven negligent. </p>






                </div>
            </div>
        </div>
    <div className='policyFooter'>
    <Footer />    
    </div>
    </>
}