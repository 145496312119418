import Header1 from '../components/header1/header1';
import section1 from './section1.jpg';
import { NavLink } from 'react-router-dom';
import './privacyshield.css';

export default function PrivacyShield2(){
    return<>
        <Header1 />
        {/* -----------------------------------------------Section 1--------------------------------------- */}
        <div className='container-fluid' id="pssection1">
            
            <img src={section1} alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-4 " id="pssec1" >
                    <h1>Privacy Shield</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-4 col-lg-1 " id="pshomeTocovid">
                <h6>Home {'>'} <span>Privacy Shield</span></h6>
            </div>
            </div>
        </div>
        {/* -------------------------------------------------Privacy Shield Text------------------------------------------ */}
        <div className='container-fluid' id="pscont">
            <div className='row'>
                <div className='col-12 col-lg-9'>
                    <h4>8. PROTECTION OF PERSONAL DATA</h4>
                    <p>We are committed to ensuring that personal data is secure. To prevent unauthorized loss,
                    alternation, destruction, access, use or disclosure, we have put in place and will maintain
                    suitable physical, administrative and technical safeguards to secure the data we process. Where 
                    appropriate and consistent with the risk, personal data shall be kept securely. Accessto
                    information systems containing personal data shall be controlled, at a minimum, by an individual
                    user identification and password with appropriate requirements for re-logging after passage of 
                    an inactive time and/or use of password-protected screensavers. Firewall protection and
                    operating system patches shall be installed on all computers containing personal data. Up-todate versions of security agency software, including malware, patches and antivirus and pest
                    patrol shall be installed on all computers containing personal data.
                    All items of equipment containing storage media shall be checked to ensure that any personal 
                    data has been removed or securely overwritten prior to disposal. Personal data that is
                    transmitted across public networks or wirelessly or stored on portable devices shallbe
                    encrypted.
                    Appropriate contractual arrangements shall be implemented for the responsibility and physical
                    protection of personal data when such information is made available to third parties. Personal 
                    data, other than that normally required by mobile users, shall only be taken off site, as 
                    necessary. Any member of staff using personal data when working from home or during off-site
                    meetings should take necessary precautions to ensure its security, including not leaving such 
                    information unattended. Any member of staff dealing with telephone inquiries should be careful
                    about disclosing any personal information held by us.
                    Any individuals who are provided with access to personal data may only use such information for
                    the purposes set out in this Privacy Policy (or as otherwise notified from timeto time) taking
                    steps to provide reasonable safeguards to protect the personal information at all times. Access 
                    to personal data by terminated employees shall be promptly withdrawn. Employees who have
                    access to personal information will be trained on privacy and security
                    5
                    measures. Unless required to be reported to government or regulatory authorities, sensitive
                    personal data, as defined below will not be disclosed without the express written consent ofthe 
                    individual (which may be given by acknowledgement of policies setting out such disclosure 
                    requirements). Personal data shall be retained and destroyed in accordance withthe Company’s
                    data retention policies.
                    Sensitive personal data is defined as an individual’s first name and last name or first initialand
                    last name in combination with one or more the following data elements that relate to such 
                    individual: Substance abuse treatment; Alcohol abuse treatment; HIV/Aids; Genetic Information;
                    and Information related to sexually transmitted diseases.
                    Individuals whose personal information has been collected by Valis Bioscience shall havethe 
                    right to access that data for review, modification or deletion. Access to review, modifyand or
                    delete personal information or otherwise manage the use and disclosure of your personal data
                    may be initiated by contacting Valis Bioscience:
                    Valis Bioscience
                    Attn: Christian Yavorsky, Senior Management1426
                    Parker Street
                    Berkeley, CA 94702
                    United States
                    Phone: (925)338-0000
                    help@valisbioscience.com
                    Valis Bioscience will follow their corporate approved policies and procedures when handlingany
                    personal data requests.</p>
                    <h4>9. ACCESS TO PERSONAL DATA</h4>
                    <p>Individuals may opt out of providing personal data, upon request. To the extent that personaldata
                    has been collected, individuals have the right to review personal data held about them and have
                    certain inaccurate information corrected, unless the burden or expense of providing access
                    would be disproportionate to the risks to the individual’s privacy in the case in question, or
                    where the rights of persons other than the individual would be violated. If you wish to do so, or to 
                    notify us of a change in your details, please contact the Privacy Officer.
                    A formal request from an individual for information that we hold about them must be made in 
                    writing. A fee is payable by the data subject for provision of this information. Any member of staff 
                    who receives a written request should forward it to the Privacy Officer immediately.</p>
                    <h4>10. NOTICE</h4>
                    <p>In addition to this posting, we will provide either a copy of this Privacy Policy or a separate privacy
                    notice to each employee, customer and contractor if we collect and use informationfrom them, 
                    which will include the type of information we collect, our Company’s contact information for any
                    inquiries or complaints, the identity of third parties to which we will
                    6
                    disclose the information, and the choices and means that we offer individuals for limitinguse
                    and disclosure of their information upon request.</p>
                    <h4>11. CHANGES TO THIS EU-U.S. PRIVACY SHIELD POLICY</h4>
                    <p>This policy may be amended from time to time, consistent with the requirements of the Privacy 
                    Shield Framework. A notice will be posted on the Valis Bioscience web page
                    (www.valisbioscience.com/eu-us-privacy-shield) for 60 days whenever this policy is changedin a
                    material way.</p>
                    <h4>12. EFFECTIVE DATE</h4>
                    <p>This policy became effective on 31JAN2021 and was last updated on 31JAN2021.</p>
                    <h4>13. INDEPENDENT RECOURSE FOR PRIVACY COMPLAINTS</h4>
                    <p>In compliance with the Privacy Shield Principles, Valis Bioscience commits to resolve complaints 
                    about our collection or use of your personal information. EU individuals withinquiries or complaints
                    regarding our Privacy Shield policy should first contact Valis Bioscience at:
                    Valis Bioscience
                    Attn: Christian Yavorsky, Senior Management1426
                    Parker Street
                    Berkeley, CA 94702
                    United States
                    Phone: (925)338-0000
                    help@valisbioscience.com
                    Valis Bioscience has further committed to cooperate with the panel established by theEU 
                    data protection authorities (DPAs) with regard to unresolved Privacy Shield complaints
                    concerning data transferred from the EU.
                    Under certain limited conditions it is possible to seek recourse through last resort binding
                    arbitration. Please note the name of the website or other online resource to which you provided 
                    the information, as well as the nature of the information that you provided. Valis Bioscience will
                    use reasonable efforts to respond promptly to requests, questions or concerns you may have 
                    regarding our use of personal information about you. Except where required by law, the Company 
                    cannot ensure a response to questions or comments regarding topics unrelated to this Policy or 
                    our privacy practices.</p>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li >
                        <NavLink to="/privacyshield" className='pslinks'>Previous</NavLink>
                        </li>
                        <li class="page-item disabled">
                        <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                    </nav>
                </div>
            </div>
        </div>
    </>
}