import './technology.css';
import section1 from './section1.jpg';
import Header1 from '../components/header1/header1';
import doctors from './doctors.jpg';
import Footer from '../components/footer/footer';

import Ai from './ai.svg';
import dlt from './dlt.svg';
import ontologies from './ontologies.svg';

export default function Technology(){
    return<>
        <Header1 />
        {/* -------------------------------------------Section 1------------------------------------------ */}
        <div className='container-fluid' id="techsection1">
            
            <img src={section1} alt="TechBackground" />
            <div className='row justify-content-center'>
                <div className="col-4 " id="techsec1" >
                    <h1>Technolgy</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-1 " id="techhomeTocovid">
                <h6>Home {'>'} <span>Technology</span></h6>
            </div>
            </div>
        </div>
        {/* ----------------------------------------------Doctors Section-------------------------------------------- */}
            <div className='container-fluid'>
            <div className='row justify-content-center' id="doctors">
                <div className='col-12 col-lg-7' >
                    <img src={doctors}  id="doctorsImg" alt="Doctors" />
                </div>
                <div className='col-12 col-lg-5' id="doctorsText">
                    <div className='row justify-content-center'>
                        <div className='col-12 col-lg-12' id="actualText">
                            <h2>The barrier to advancing mental health treatment is measurement.</h2>
                            <p>CNS disorders are complicated to measure
                            <br />Existing measurement relies on clinic-based verbal and observational assessment of symptoms. Subjective clinician reported data is unreliable and expensive
                            <br />85% of CNS trials fail. 95% of Rare and Orphan trials in CNS fail
                            <br />Pharma is moving to siteless trials, and needs validated, decentralized measurement tools to enable them.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* ------------------------------------------------Digital Section------------------------------------------- */}
        <div className='container-fluid'>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-5' id="digitalHeading">
                    <h2>Valis is building new digital tools.</h2>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-7' id="digitalPara">
                <p> Valis is creating digital applications and tools to more accurately measure mental health disorders.  
                <br />Our applications are fast to deploy, user-friendly, scientifically validated, and built by researchers for researchers.
                <br />Our products are evolving to include automated interventions to prevent relapse, suicide, and adverse events as well as digital therapies delivered through the same applications that collect data. </p>
                </div>
            </div>
        </div>
        <div className='row justify-content-center'>
        <div className="card-group" id="allCards">
            <div className="card text-center"  id="aiCard">
                <img src={Ai} className="card-img-top" id="aiSvg" alt="AI" />
                <div className="card-body">
                <h5 className="card-title">AI</h5>
                <p className="card-text">AI lets our applications learn from data to drive conclusions and protect patients with automated interventions and microtherapies</p>
                </div>
            </div>
            <div className="card text-center"  id="dltCard">
                <img src={dlt} id="aiSvg" className="card-img-top" alt="..." />
                <div className="card-body">
                <h5 className="card-title">DLT</h5>
                <p className="card-text">Distributed Ledger Technology ensures data provenance, auditability, and protects PHI</p>
                </div>
            </div>
            <div className="card text-center"  id="ontologiesCard">
                <img src={ontologies} id="aiSvg" className="card-img-top" alt="..." />
                <div className="card-body">
                <h5 className="card-title">Ontologies</h5>
                <p className="card-text">Data frameworks and ontologies standardize information so it can better be analyzed, learned from, and reproduced</p>
                </div>
            </div>
            </div>
        </div>
    <div className='techFooter'>
    <Footer />
    </div>
    </>
}