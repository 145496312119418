import Header1 from '../components/header1/header1';
import section1 from './section1.jpg';
import './conditions.css';
import Footer from '../components/footer/footer';

export default function Conditions(){
    return<>
        <Header1 />
        {/* ------------------------------------------Section 1----------------------------------------------- */}
        <div className='container-fluid' id="asection1">
            
            <img src={section1} alt="Privacy Policy" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-4 " id="asec1" >
                    <h1>Terms & Conditions</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-12 col-lg-1" id="ahomeTocovid">
                <h6>Home {'>'} <span>Terms & Conditions</span></h6>
            </div>
            </div>
        </div>
        {/* -------------------------------------------------Terms & Conditions Text------------------------------------------ */}
        <div className='container-fluid' id="cont">
            <div className='row'>
                <div className='col-12 col-lg-9'>
                    <h1>Our Terms And Conditions</h1><br />
                    <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using any Valis Biosciences, Inc. website (https://www.valisbioscience.com, https://www.trial.training, https://www.valisr.com) and the any Valis web-app (together, or individually, the "Service") operated by Valis Biosciences, Inc. ("Valis", "us", "we", or "our").
</p>
                    <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.
</p>
                    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.
</p>
                    <h4>Intellectual Property</h4>
                    <p>The Service and its original content, features and functionality are and will remain the exclusive property of Valis Biosciences, Inc. and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Valis Biosciences, Inc.

</p>
                    <h4>Links to Other Web Sites</h4>
                    <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Valis Biosciences, Inc.
</p>
                    <p>Valis Biosciences, Inc. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
</p>
                    <p>You acknowledge and agree that Valis Biosciences, Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.
</p>
                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third party-web sites or services that you visit.
</p>
                    <h4>Availability of and Access to Services</h4>
                    <p>We will attempt to provide continuous availability and access to Services. In the event that we are unable to provide access for reasons beyond our control, we will communicate the reasons for the outage and expected duration of the outage clearly and explicitly to the Customer. These outages could be due to third parties that the Service depends on, such as, but not limited to Amazon AWS or other third-party service providers. Also, while we will make commercially reasonable attempts to backup all Customer data, in the event of recovery from disaster, the Customer may have to reconfigure the Service to get it back to the state it was in before the outage. We and our third party service providers have implemented and maintain commercially reasonable technical and organizational security measures designed to meet the following objectives: (a) ensure the security and confidentiality of Customer data; (b) protect against anticipated threats or hazards to the security or integrity of Customer data; (c) protect against unauthorized access to or use of Customer data; (d) encrypt Customer's Content and data during transmission by us and our third party service providers and when being uploaded by Customer for use in connection with the Services using an https connection; and (e) ensure that we return or dispose of Customer data is performed in a manner consistent with the foregoing. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures to access your data for improper purposes. You acknowledge that there are risks inherent in Internet connectivity that could result in the loss of your privacy, confidential information and property.
</p>
                    <h4>Termination</h4>
                    <p>We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
</p>
                    <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
</p>
                    <h4>Indemnification</h4>
                    <p>You agree to defend, indemnify and hold harmless Valis Biosciences, Inc. and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, or b) a breach of these Terms.
</p>
                    <h4>Limitation of Liability</h4>
                    <p>In no event shall Valis Biosciences, Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
</p>
                    <h4>Disclaimer</h4>
                    <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied.
</p>
                    <h4>Exclusions</h4>
                    <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
</p>
                    <h4>Your Responsibilities</h4>
                    <h5>You shall:</h5>
                   
                        <li>Be responsible for Your compliance with these Terms and all activities occurring within or from this website or our Service,</li>
                        <li>Use commercially reasonable efforts to prevent unauthorized access to or use of the Service, and notify us promptly of any such unauthorized access or use, and
</li>
                        <li>Use the Services only in accordance with these Terms, for its intended purpose and in compliance with all applicable laws and government regulations.
</li>               
<br />      
                    <h5>You shall not:​</h5>
                    
                        <li>Impersonate any other person; or misrepresent authorization to act on behalf of others or us</li>
                        <li>Falsely say or imply that You are associated with Us, another person or entity;</li>
                        <li>Submit content in exchange for payment or other consideration from another person or entity;</li>
                        <li>Make the Service available to anyone else, including sell, resell, rent or lease;</li>
                        <li>Use the Service to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights;</li>
                        <li>Use the Service to store or transmit malicious code or malware, or to engage in phishing or other fraudulent activity.</li>
                        <li>Interfere with the integrity or performance of the Service or any data contained therein;</li>
                        <li>Attempt to gain unauthorized access to the Service or Valis Biosciences, Inc. systems or networks;</li>
                        <li>Submit or link to any content (including any name or User ID You use in the Valis services) that:</li>
                        <li>Infringes or violates the intellectual property or other rights of any person or entity;</li>
                        <li>Violates anyone’s privacy or publicity rights;</li>
                        <li>Breaches any confidentiality that You owe to anyone.</li>
                        <li>Provides any nonpublic information about Us or any other company or person without authorization (including the names and contact information for our employees).
</li>
<br />              
                    <h4>Governing Law</h4>
                    <p>These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions. 
</p>
                    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
</p>
                    <h4>Changes</h4>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 15 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
</p>
                    <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
</p>
                    <h4>Contact Us</h4>
                    <p>If you have any questions about these Terms, please contact us at</p>
                    <p>trust@valisbioscience.com or use the form below.</p>
                </div>
            </div> 
        </div> 
    <div className='conditionsFooter'>    
    <Footer />
    </div>
    </>
}