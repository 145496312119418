import Footer from "../components/footer/footer";
import Header1 from "../components/header1/header1";
import './careers.css';
import section1 from './section1.jpg';

export default function Careers(){
    return<>
        <Header1 />
        {/* ------------------------------------------Section 1-------------------------------------------- */}
        <div className='container-fluid' id="careerssection1">
            
            <img src={section1} alt="Careers" />
            <div className='row justify-content-center'>
                <div className="col-2 " id="careerssec1" >
                    <h1>Careers</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-1 " id="careershomeTocovid">
                <h6>Home {'>'} <span>Careers</span></h6>
            </div>
            </div>
        </div>
        {/* --------------------------------------------Positions----------------------------------------------- */}
        <div className="container-fluid">
            <div className="row justify-content-center" id="openPositions">
                <h2>Open Positions</h2>
                
            </div>
            <div className="row" id="positionsRow">
            <ul className="nav justify-content-center ">

                <li className="nav-item">
                   All Positions
                </li>
                <li className="nav-item">
                   Positions name 1
                </li>
                <li className="nav-item">
                   Positions name 2
                </li>
                <li className="nav-item">
                   Positions name 3
                </li>
                

                </ul>
            </div>

            <div className="row justify-content-center">
            <hr className="line4positions" />
            </div>
            <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
            <div class="card" id="pc1">
                <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            <div class="card" id="pc1">
            <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            <div class="card" id="pc1">
                <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            </div>

            <div className="row row-cols-1 row-cols-lg-3 g-4 justify-content-center">
            <div class="card" id="pc1">
                <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            <div class="card" id="pc1">
            <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            <div class="card" id="pc1">
                <div class="card-body">
                    <h5 class="card-title">Open Position's name</h5>
                    <p class="card-text"><i className="bi bi-geo-alt-fill" id="loc"></i>Location name</p>
                </div>
            </div>
            </div>

        </div>



    <div className="careersFooter">
    <Footer />
    </div>
    </>
}