import React from "react";
import Footer from "../components/footer/footer";
import Header1 from "../components/header1/header1";
import './covid19.css';

//Main Section
import mainSection from './mainSection.jpg';

//Pic only
import chart from './sec2.jpg';

import cards from './covidcardbg.jpg';

export default function Covid(){
    return<>
        <Header1 />
        {/* -------------------------------------Main Section--------------------------------------------- */}
        
        
        <div className='container-fluid' id="mainCoviddiv">
            
            <img src={mainSection} id="mainSection" alt="Covid-19" />
            <div className='row justify-content-center'>
                <div className="col-12 col-lg-4 " id="sec1" >
                    <h1>COVID-19</h1>
                    
                </div>
            </div>
            <div className='row justify-content-center'   >
            <div className="col-12 col-lg-1 " id="homeTocovid">
                <h6>Home {'>'} <span>Covid-19</span></h6>
            </div>
            </div>
       
        
        {/* ----------------------------------------Pic Only Section------------------------------------------- */}
        
            <div className='row justify-content-center' >
                <div className='col-sm-4 col-lg-8' >
                <img src={chart} id="picOnly" className="img-fluid" alt="C" />
                </div>
            </div>
            
        
        {/* -------------------------------------------Section 3----------------------------------------------- */}
        
            <div className='row' id="section3">
                <div className='col-12 col-lg-5' id="part1">
                    <div className='leftSide'>
                    <h3>COVID-19 is forcing a change in how we do research.</h3>
                    <p>Valis has prepared resources to support understanding how the pandemic will affect the research landscape, and what can be done.</p>
                    <h6>The resources include: </h6>
                    
                        <li id="rp1">Research interruptions due to flattening of coronavirus curve</li>
                        <li id="rp2">Non-invasive monitoring devices</li>
                        <li id="rp3">Remote monitoring</li>
                        <li id="rp4">Remote and virtual assessment options</li>
                   
                    </div>
                </div>
                <div className='col-12 col-lg-7' id="part2">
                <div className='rightSide'>
                    <h3>Our Response to COVID-19</h3>
                    <p id="p2p1">Valis is committed to stopping the spread of the novel Coronavirus (COVID-19) and supporting our customers during the current global pandemic. We are dedicated to maintaining the continuity of research procedures and ensuring the safety of all research participants, families, and investigative sites. To do so, we have put in place the following protective measures:</p>
                    
                        <li id="r1">Remote work for all employees</li>
                        <li id="r2">Suspension of employee travel</li>
                        <li id="r3">Implementation of our robust business continuity plan</li>
                        <li id="r4">Daily meetings to assess coronavirus-related developments</li>
                        <li id="r5">Outreach to existing customers regarding considerations for scientific alternatives to face-to-face assessments</li>
                        <li id="r6">Services and support for psychometric assessment administration and site staff education</li>
                    
                    <div className='col-lg-6'>
                    <p id="p2p2">For individuals and Sponsors on the forefront of COVID-19 vaccine development, we are offering abrupt and discounted access to our services and eLearning platform. We look forward to working with and helping our customers and clinical trials research investigators in the life sciences industry during this unprecedented time.</p>
                    </div>
                    <div className='col-lg-6' id="nestp2">
                        <h4>Christian Yavorsky</h4>
                        <p>Chief Scientific Officer, Valis Bioscience</p>
                    </div>
                    </div>
                </div>
            </div>
            
        {/* -----------------------------------------------Cards Section-------------------------------------- */}
        
            <div className='row justify-content-center' id="covidCards" >
                <div className=' col-12 col-lg-auto ' id="cardRow1">
                <h2>COVID-19 Resources and Links</h2>
                <p>These sources, references, and links have been vetted by the Valis team</p>
                </div>
            </div>
            <div className='row row-cols-1 row-cols-md-4 g-4 justify-content-center '>
                <div className='col' >
                    
                    <div className="card" id="blueCard1" >
                        <div className="card-body">
                            <h5 className="card-title h-25">01</h5>
                            <p className="card-text">
                            Johns hopkins coronavirus resource center
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
                <div className='col' >
                    <div className="card" id="blueCard1" >
                        <div className="card-body">
                            <h5 className="card-title h-25">02</h5>
                            <p className="card-text">
                            FDA coronavirus homepage 
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
                <div className='col' >
                    <div className="card" id="blueCard1" >
                        <div className="card-body">
                            <h5 className="card-title h-25">03</h5>
                            <p className="card-text">
                            FDA Guidance on Conduct of Clinical Trials during COVID-19 Pandemic
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row row-cols-1 row-cols-md-4 g-4 justify-content-center '>
                <div className='col' >
                    <div className="card" id="blueCard2" >
                        <div className="card-body">
                            <h5 className="card-title h-25">04</h5>
                            <p className="card-text">
                            National Alliance on Mental Illness Coronavirus Guide and Resource
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
                <div className='col' >
                    <div className="card" id="blueCard2" >
                        <div className="card-body">
                            <h5 className="card-title h-25">05</h5>
                            <p className="card-text">
                            Center for Disease Control Coronavirus Homepage
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
                <div className='col' >
                    <div className="card" id="blueCard2" >
                        <div className="card-body">
                            <h5 className="card-title h-25">06</h5>
                            <p className="card-text">
                            Harvard Medical School Coronavirus Resource Center
                            </p>
                            <a href="#">Visit now!</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="covidFooter">
        <Footer />
        </div>
    </>
}